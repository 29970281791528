
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import ApiService from "@/core/services/ApiService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface IAlHistory {
  pdate: string;
  memo: string;
  bettings: string;
  winamount: string;
  loseamount: string;
  historys: string;
  withdrawals: string;
  al_before: string;
  amount: string;
  al_after: string;
}

interface DateRange {
  start: Date;
  end: Date;
}

export default defineComponent({
  name: "subagent-al-history",
  components: {
    StatisticsWidget5,
    Datatable,
    DateRangePicker,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    const route = useRoute();
    // page variables
    const tableHeader = ref([
      {
        name: t("Deposit.Date"),
        key: "pdate",
        text: true,
        sortable: true,
      },
      {
        name: t("Deposit.Type"),
        key: "memo",
        customslot: true,
      },
      {
        name: t("BetSummary.BetAmount"),
        key: "bettings",
        sortable: true,
        currency: true,
      },
      {
        name: t("BetSummary.WinAmount"),
        key: "winamount",
        sortable: true,
        currency: true,
      },
      {
        name: t("AlHistory.Before"),
        key: "al_before",
        currency: true,
      },
      {
        name: t("AlHistory.Usage"),
        key: "amount",
        customslot: true,
      },
      {
        name: t("AlHistory.After"),
        key: "al_after",
        currency: true,
      },
    ]);

    const tableData = ref<Array<IAlHistory>>([]);
    const totalUsage = ref<number>(0);
    const subAgent = ref({
      code: "",
      al_balance: 0,
      id: "",
    });

    /**
     * Get Al Histories
     *
     */
    const getAlHistories = async (date: DateRange) => {
      const results: IAlHistory[] = await ApiService.get(
        `/agent/subagents/al/histories?subAgentId=${route.params.subagentid}&start=${date.start}&end=${date.end}`
      )
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...results);
      if (results.length > 0)
        totalUsage.value = results
          .filter((r) => r.memo === "SETTLEMENT")
          .map((item) => Number(item.amount))
          .reduce((prev, next) => Number(prev) + Number(next));
    };

    /**
     * Sub Agent Info
     *
     */
    const getSubAgentInfo = async (subAgentId: string) => {
      const info = await ApiService.get(`/agent/subagent/${subAgentId}`).then(
        (res) => res.data
      );

      if (info.code) {
        subAgent.value = info;
        setCurrentPageBreadcrumbs(info.code + " " + t("Menu.AlHistory"), [
          t("Menu.AgentManage"),
        ]);
      }
    };

    onMounted(() => {
      // call info
      getSubAgentInfo(String(route.params.subagentid));
      // init menus
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Agents.Name") + " " + t("Menu.AlHistory"), [
        t("Menu.AgentManage"),
      ]);
    });

    return {
      tableData,
      tableHeader,
      totalUsage,
      subAgent,
      getAlHistories,
    };
  },
});
