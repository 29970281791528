import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-3 col-lg-3 col-md-6 col-sm-6" }
const _hoisted_3 = { class: "col-xxl-3 col-lg-3 col-md-6 col-sm-6" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-header border-0 pt-5" }
const _hoisted_6 = { class: "d-flex flex justify-content-end" }
const _hoisted_7 = { class: "card-body pt-0" }
const _hoisted_8 = {
  key: 0,
  class: "badge badge-success"
}
const _hoisted_9 = {
  key: 1,
  class: "badge badge-danger"
}
const _hoisted_10 = {
  key: 2,
  class: "badge badge-primary"
}
const _hoisted_11 = {
  key: 3,
  class: "badge badge-info"
}
const _hoisted_12 = {
  key: 0,
  class: "text-success"
}
const _hoisted_13 = {
  key: 1,
  class: "text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsWidget5 = _resolveComponent("StatisticsWidget5")!
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-8",
          "svg-icon": "/media/icons/duotune/finance/fin006.svg",
          color: "info",
          "icon-color": "white",
          title: _ctx.$n(Number(_ctx.subAgent.al_balance), 'currency'),
          description: _ctx.$t('DashboardSummary.CurrentAl')
        }, null, 8, ["title", "description"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-8",
          "svg-icon": "/media/icons/duotune/finance/fin001.svg",
          color: "success",
          "icon-color": "white",
          title: _ctx.$n(_ctx.totalUsage, 'currency'),
          description: _ctx.$t('AlHistory.TotalUsage')
        }, null, 8, ["title", "description"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_DateRangePicker, {
            onChangedate: _ctx.getAlHistories,
            initial: "month"
          }, null, 8, ["onChangedate"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_Datatable, {
          "table-data": _ctx.tableData,
          "table-header": _ctx.tableHeader,
          "enable-items-per-page-dropdown": true
        }, {
          "cell-memo": _withCtx(({ row: history }) => [
            (history.memo === 'ADD' || history.memo === 'SUB_DEDUCT')
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("Deposit." + history.memo)), 1))
              : (history.memo === 'DEDUCT' || history.memo === 'SUB_ADD')
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("Deposit." + history.memo)), 1))
                : (history.memo === 'SETTLEMENT')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("AlHistory.Settlement")), 1))
                  : (history.memo === 'SNOW2_SETTLEMENT')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("AlHistory.Snow2Settlement")), 1))
                    : _createCommentVNode("", true)
          ]),
          "cell-amount": _withCtx(({ row: history }) => [
            (Number(history.amount) >= 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$n(Number(history.amount))), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$n(Number(history.amount))), 1))
          ]),
          _: 1
        }, 8, ["table-data", "table-header"])
      ])
    ])
  ], 64))
}